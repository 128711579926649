import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

import {OptimizationEffect} from "@domain/OptimizationEffect";
import {TMlModuleKey} from "@shared/interfaces/ml-module.type.";
import {TargetSettings} from "@domain/TargetSettings";

@Injectable({
  providedIn: 'root'
})
export class OptimizationEffectEndpointService {

  constructor(private http: HttpClient) {
  }

  fetchData(moduleKey: TMlModuleKey, positionBookId: number, targetKey?: string): Observable<OptimizationEffect[]> {

    const queryParams = `?positionBookId=${positionBookId}${targetKey ? `&optimizationTargetKey=${targetKey}` : ''}`

    return this.http.get<OptimizationEffect[]>(`/api/modules/${moduleKey}/optimizations_effects${queryParams}`);
  }

  getTargets(moduleKey: TMlModuleKey): Observable<TargetSettings[]> {
    return this.http.get<TargetSettings[]>(`/api/modules/${moduleKey}/targets`)
  }
}

import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
@Injectable()
export class BufferingDataService {

  buffer$ = new BehaviorSubject<any>(null);

  get data(): any {
    return this.buffer$.value;
  }

  set data(value: any) {
    const temp = {...this.data, ...value};
    this.buffer$.next(temp);
  }

  clear(): void {
    this.buffer$.next(null);
  }
}

import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {RoleType} from './domain/Role';
import {User} from './domain/User';
import {ReportTemplate} from './domain/grabber/ReportTemplate';
import {FormGroupTypeSafe} from "./shared/helpers";
import {IGroupedDataForm} from "./shared/interfaces/data-set.interface";

export class CustomValidator {
  static validatePassword(formGroup: FormGroup) {

    const password = formGroup.get('password').value;
    const repeatPassword = formGroup.get('confirmPassword').value;

    if (!password && !repeatPassword) {
      return null;
    }

    if (repeatPassword !== password) {
      return {
        doesMatchPassword: true
      };
    }

    return null;
  }

  static validateEmail(control: AbstractControl): {[key: string]: boolean} {
    const pattern = validationPattern.email;
    if (pattern.test(control.value)) {
      return undefined;
    }
    return {'email': true};
  };

  static dataType = (dataType) => typeof dataType;
};

export const validationPattern = {
  numeric: '^[0-9]*$',
  email: /.+@.+\..+/i,

}

export function requiredRoleValidator(fn: (() => User)) {
  return (group: FormGroup) => {
    const user = fn();
    if (user.roles && user.roles.filter(v => v === RoleType.DATA_MANAGER).length === 0) {
        return {
          required: true
        };
    }
    return null;
  };
};

export function requiredRoleGroupValidator(): ValidatorFn {
  return (group: FormGroup): ValidationErrors | null => {
    const roles = group.get('roles').value;
    const clients = group.get('clients').value;
    if (roles && roles.length === 0) {
      return {
        roleRequired: true
      };
    }
    if (roles && roles.some(v => v === RoleType.DATA_MANAGER || v === RoleType.REPORTER)) {
      if (!clients || clients.length === 0) {
        return {
          clientRequired: true
        };
      }
    }
    return null;
  };
}

export function requiredDataSourceClientValidator(): ValidatorFn   {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const allowClientType = formGroup.controls.allowClientType.value;
    const allowedClients = formGroup.controls.allowedClients.value;
    if (allowClientType === 'SELECTED' && allowedClients && allowedClients.length === 0) {
        return {
          clientRequired: true
        };
    }
    return null;
  };
};

export function requiredReportTemplateClientValidator(fn: (() => ReportTemplate)) {
  return (formGroup: FormGroup) => {
    const allowType = formGroup.controls.allowType.value;
    const reportTemplate = fn();
    if (allowType === 'SELECTED' && reportTemplate.allowedClients && reportTemplate.allowedClients.length === 0) {
        return {
          clientRequired: true
        };
    }
    return null;
  };
};


export function requiredModulesValidator(fn?: (() => any)): ValidatorFn {
  return (formControl: FormControl): ValidationErrors | null => {
    // TODO: fn() can be removed after refactoring;
    const modules = fn && fn() || formControl.value;
    if (!modules || modules.length === 0) {
      return {
        moduleRequired: true
      };
    }
    return null;
  };
}

export function requiredMetricValidator(): ValidatorFn   {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const approvalRequired = formGroup.controls.approvalRequired.value;
    const approvalMetrics = formGroup.controls.approvalMetrics.value;
    if (!approvalRequired && (!approvalMetrics || approvalMetrics.length === 0)) {
      return {
        metricRequired: true
      };
    }
    return null;
  };
};

export function sameValuesValidator(): ValidatorFn {
  return (formGroup: FormGroupTypeSafe<IGroupedDataForm>): ValidationErrors | null => {
    const sources = formGroup.get('sources').value;
    const srcLength = sources.length;
    if (srcLength > 1) {
      const dfName = (ind) => sources[ind] && sources[ind].dataFormat.name;

      if (!sources[1].dataFormat || !dfName(1)) {
        return null;
      }
      let sameDFDetected;
      sameDFDetected = findSameValueInArrayByField(dfName, srcLength);

      if (sameDFDetected) {
        let sameAggrDetected = false;
        let sameFieldDetected = false;
        let sameValueDetected = false;
        const aggrg = (ind) => sources[ind] && sources[ind].aggregation;
        const field = (ind) => sources[ind] && sources[ind].groupField;
        const value = (ind) => sources[ind] && sources[ind].groupValue;
        if (!sources[1].aggregation || !aggrg(1)) {
          return null;
        }
        if (!sources[1].groupField || !field(1)) {
          return null;
        }
        if (!sources[1].groupValue || !value(1)) {
          return null;
        }
        sameAggrDetected = findSameValueInArrayByField(aggrg, srcLength);
        sameFieldDetected = findSameValueInArrayByField(field, srcLength);
        sameValueDetected = findSameValueInArrayByField(value, srcLength);

        if (sameAggrDetected && sameFieldDetected && sameValueDetected) {
          return {
            sameDataFormats: true
          }
        }
      }
    }
    return null;
  };
};

export function findSameValueInArrayByField(fieldSet: Function, setLength: number): boolean {
  /**
   * An algorithm with recursion for finding the same name in array of sources array has been implemented;
   */
  let sameValueDetected = false;
  let curIndex;
  // Function with recursion;
  const checkSetWithIndex = (_compareInd) => {
    if (fieldSet(_compareInd) && fieldSet(curIndex) && fieldSet(_compareInd) === fieldSet(curIndex) && _compareInd !== curIndex) {
      sameValueDetected = true;
      return
    }
    curIndex--;
    if (curIndex >= 0) {
      checkSetWithIndex(_compareInd);
    }
  }
  let compareInd = 0;
  // Function with recursion;
  const checkSet = () => {
    if (!sameValueDetected && compareInd <= (setLength - 1)) {
      curIndex = setLength - 1;
      checkSetWithIndex(compareInd);
      compareInd++;
      checkSet();
    }
  }
  checkSet();

  return sameValueDetected;
}
